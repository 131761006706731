import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import {
  TextField as MuiTextField,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";
import {
  Search as MuiSearchIcon,
  ArrowDropDown as MuiArrowDropDownIcon,
} from "@material-ui/icons";
import axios from "axios";
import PropTypes from "prop-types";
import { moreItemsOption } from "../../../Utils/Autocomplete";
import { useAlertContext } from "@stanford-tds/as-components";
import { useCommonStyles } from "../../shared/common.styles";

export const AUTOCOMPLETE_DEBOUNCE_DELAY = 500; // Delay after user pauses typing before calling API (in milliseconds)
export const AUTOCOMPLETE_MIN_INPUT = 2; // Minimum number of characters entered by user before calling API

/**
 * `Autocomplete` is a component that renders an MUI autocomplete input for filtering.
 *
 * @component
 * @param {string} label - The label for the autocomplete input.
 * @param {string} value - The value of the autocomplete.
 * @param {Function} onChange - The function to call when the autocomplete input's value changes.
 * @param {boolean} [required=false] - Indicates if the autocomplete input is a required field.
 * @param {boolean} [disabled=false] - Indicates if the autocomplete input is disabled.
 * @param {boolean} [clientSide=false] - Indicates if the autocomplete is a client side.
 * @param {Array} [options=[]] - The initial set of options displayed in the autocomplete dropdown/popup.
 */

let timeoutID;
export const Autocomplete = ({
  label,
  value,
  options = [],
  onChange,
  autoCompleteUrlPrefix,
  required = false,
  disabled = false,
  clientSide = false,
  ...passedParams
}) => {
  const { setAlert, clearAlert } = useAlertContext();
  const classes = useCommonStyles();
  const { t } = useTranslation();

  const [optionsList, setOptionsList] = useState(options || []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (options.length) setOptionsList(options);
  }, [options]);

  const handleSearchStringChange = (event) => {
    const searchQuery = event.target.value.trim();

    clearTimeout(timeoutID); // Cancel current debounce timer

    // Start debounce timer and call API when timer finishes
    timeoutID = setTimeout(async () => {
      if (searchQuery?.length >= AUTOCOMPLETE_MIN_INPUT) {
        const cancelSource = axios.CancelToken.source();
        try {
          setLoading(true);
          clearAlert();
          const fetchSize = 10;

          const response = await axios({
            url: autoCompleteUrlPrefix,
            params: { value: searchQuery, size: fetchSize },
            cancelToken: cancelSource?.token,
          });

          const options = response?.data?.values || [];
          // pushing helper text as an option when the options are greater than or equal to fetchSize
          if (options.length >= fetchSize) {
            options.push(moreItemsOption(options.length));
          }
          setOptionsList(options);
        } catch (error) {
          setAlert("error", error?.message);
        } finally {
          setLoading(false);
        }
      } else {
        setOptionsList([searchTooShortOption(AUTOCOMPLETE_MIN_INPUT, t)]);
      }
    }, AUTOCOMPLETE_DEBOUNCE_DELAY);
  };

  return (
    <MuiAutocomplete
      {...passedParams}
      options={optionsList}
      getOptionDisabled={(option) => option.isDummy}
      defaultValue={null}
      value={value}
      blurOnSelect={true}
      clearOnEscape={true}
      clearOnBlur={true}
      closeIcon={false}
      loading={loading}
      onChange={onChange}
      disabled={disabled}
      onClose={() => !clientSide && setOptionsList([])}
      noOptionsText={t("globals.autocomplete.helperText")}
      popupIcon={clientSide ? <MuiArrowDropDownIcon /> : <MuiSearchIcon />}
      classes={{ root: !clientSide && classes.autocompleteRoot }}
      renderInput={(params) => (
        <RenderInput
          params={params}
          clientSide={clientSide}
          label={label}
          required={required}
          loading={loading}
          handleSearchStringChange={handleSearchStringChange}
        />
      )}
    />
  );
};

Autocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  clientSide: PropTypes.bool,
  options: PropTypes.array,
  autoCompleteUrlPrefix: PropTypes.string,
};

const searchTooShortOption = (nMinChars, t) => ({
  value: {},
  displayText: t("globals.autocomplete.searchTooShortText", {
    nMinChars: nMinChars,
  }),
  isDummy: true,
});

const RenderInput = ({
  params,
  clientSide,
  label,
  required,
  loading,
  handleSearchStringChange,
}) => {
  if (clientSide) {
    return (
      <MuiTextField
        {...params}
        label={label}
        variant="outlined"
        required={required}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? (
                <MuiCircularProgress color="inherit" size={20} />
              ) : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    );
  }

  return (
    <MuiTextField
      {...params}
      label={label}
      variant="outlined"
      required={required}
      onChange={handleSearchStringChange}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading ? <MuiCircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );
};
