import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextField as MuiTextField } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { Check as MuiCheckIcon } from "@material-ui/icons";
import { useSelectedSubmission } from "../../../services/selectedSubmissionService";
import { AutocompleteOption } from "../../../Utils/Autocomplete";
import { useCommonStyles } from "./Submission.styles";

export const SubmissionSelect = ({
  submissionList,
  handleChangeSubmission,
  setSelectedSubmissionId,
}) => {
  const [getSelectedSubmission, setSelectedSubmission] =
    useSelectedSubmission().value;
  const [, setSelectedSubmissionYear] = useSelectedSubmission().year;
  const [, setSelectedSubmissionName] = useSelectedSubmission().name;
  const [, setSelectedSubmissionMonth] = useSelectedSubmission().month;
  const [selectedValue, setSelectedValue] = useState("");
  const [submissionOptions, setSubmissionOptions] = useState([]);
  const classes = useCommonStyles();
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    const value = newValue ? newValue._links.self.href.split("/").pop() : "";
    setSelectedSubmission(value);
    setSelectedValue(newValue ?? "");
    setSelectedSubmissionName(newValue?.name ?? "");
    setSelectedSubmissionYear(newValue?.year ?? "");
    setSelectedSubmissionMonth(newValue?.month ?? "");
    if (handleChangeSubmission) {
      handleChangeSubmission();
    }
  };

  const submissionExists = useCallback(() => {
    return submissionOptions.find(function (submissionValue) {
      if (
        submissionValue._links.self.href.split("/").pop() ===
        getSelectedSubmission()
      ) {
        return submissionValue;
      }
      return null;
    });
  }, [getSelectedSubmission, submissionOptions]);

  useEffect(() => {
    if (submissionList && submissionList.length) {
      const submissionVal = submissionExists();
      if (submissionVal) {
        setSelectedValue(submissionVal);
      }
    }
  }, [submissionExists, submissionList]);

  useEffect(() => {
    if (submissionList && submissionList.length) {
      const extendedOptionList = submissionList.map((item) => {
        const submissionMonth =
          item.month.toString().length < 2 ? `0${item.month}` : item.month;
        return {
          ...item,
          displayText: `${item.name}, ${item.year}/${submissionMonth}`,
          value: {},
        };
      });

      setSubmissionOptions(extendedOptionList);
    }
  }, [submissionList]);

  return (
    <MuiAutocomplete
      id="submission-selection"
      disableClearable
      autoHighlight
      clearOnEscape
      blurOnSelect
      options={submissionOptions}
      getOptionLabel={(option) => option?.displayText || ""}
      value={selectedValue}
      onChange={(event, newValue) => {
        handleChange(event, newValue);
      }}
      renderOption={(option, { inputValue, selected }) => (
        <AutocompleteOption
          classes={classes}
          option={option}
          inputValue={inputValue}
        >
          {selected && (
            <MuiCheckIcon
              style={{
                fill: "#009ABB",
                marginLeft: "auto",
                marginRight: "0",
              }}
            />
          )}
        </AutocompleteOption>
      )}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          label={t("faculty.mainView.submission")}
          variant="outlined"
        />
      )}
    />
  );
};
